<template>
  <mynav navIndex="4"></mynav>
  <div class="main">
    <h3>美国CPI数据</h3>
    <div class="lineChart-container">
      <canvas id="myChart" class="lineChart"></canvas>
    </div>

    <div class="div-x-place">
      <!-- <div class="title" @click="titleClick" :class="{activeTitle: dtype == 0}" :data-dtype="0"> -->
      <a href="/cpi/year.html" rel="noopener noreferrer" class="title" :class="{'title-strong': dtype==1}" >
        CPI年率
        <div :class="{lineTitle: dtype == 1}"></div>
      </a>
      <a href="/cpi/month.html" rel="noopener noreferrer" class="title" :class="{'title-strong': dtype==0}" >
        CPI月率
        <div :class="{lineTitle: dtype == 0}"></div>
      </a>
    </div>

    <table class="table table-striped">
    <thead>
    <tr>
    <th>所属月</th>
    <th>预测值(%)</th>
    <th>公布值(%)</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in datas" v-bind:key="index">
      <td>{{data.date}}</td>
      <td>{{data.forecast}}</td>
      <td>{{data.now_ratio}}</td>
      </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import axios from "axios";
import datos from "../apps.js"
export default {
  name: 'Cpi',
  data() {
    return {
      configChart: {
        type: 'line',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'index'
          },
          elements:{
            point:{
              radius: 2,
            }
          }
        }
      },
      datas:[],
      num: 0,
      dtype: {'year': 1, 'month': 0}[this.$route.params.type],
      chart: null,
    }
  },
  watch: {
    dtype(){
      this.datas = []
      this.getData()
    }
  },
  mounted() {
    this.num = Math.floor(Math.random()*40)
    this.getData()
  },
  unmounted() {
    this.datas = []
  },
  methods: {
    titleClick(e){
      let {
        dtype
      } = e.target.dataset
      this.dtype = dtype
    },
    getData(){
      axios
      .get("/" + datos()[0].desc + "/v1/cpi/data/list", {params: {
          dtype: this.dtype, page: this.page}})
      .then((data) => {
        let lineLables = []
        let lineDataPush = {
          label: '公布值',
          backgroundColor: 'rgb(111, 190, 233)',
          borderColor: 'rgb(111, 190, 233)',
          data: [],
        }
        let lineDataforecast = {
          label: '预测值',
          backgroundColor: 'rgb(250, 188, 146)',
          borderColor: 'rgb(250, 188, 146)',
          data: [],
        }
        if (data.data.code == 200) {
          for (let index = 0; index < data.data.data.length; index++) {
            var elem = data.data.data[index]
            this.datas.push({
              date: elem.date, forecast: elem.forecast / 1000,
              now_ratio: elem.now_ratio / 1000})

            lineLables.push(elem.date)
            lineDataPush.data.push(elem.now_ratio / 1000)
            lineDataforecast.data.push(elem.forecast / 1000)
          }
          this.configChart.data.labels=lineLables.reverse()
          lineDataPush.data = lineDataPush.data.reverse()
          lineDataforecast.data = lineDataforecast.data.reverse()
          this.configChart.data.datasets = [lineDataPush, lineDataforecast]
          new Chart(
          document.getElementById('myChart'),
          this.configChart)
        }

      })
      .catch((err) => {
        console.log(err);
      })
    },
  }
}
</script>
<style>
  @import '../css/main.css';
  a{
    cursor: pointer;
    text-decoration: none;
  }
  .lineChart-container{

  }
  .lineChart{
    max-height: 400px;
  }
  .title {
    font-size: 1.1rem;
    text-align: center;
    width: 50%;
    color: #2c3e50;
  }
  .title-strong{
    font-weight: 600;
  }
</style>